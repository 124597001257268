'use strict'

const _setHeight = () => {
	const _vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${_vh}px`);
}
window.addEventListener('resize', () => {
	_setHeight();
});
_setHeight();
