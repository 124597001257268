'use strict'
import slick from 'slick-carousel';
if($('.p-homeDetail__slider').length){
	$('.p-homeDetail__content').slick({
		accessibility: false,
		arrows: true,
		dots: true,
		infinite: true,
		slidesToShow: 1,
    //adaptiveHeight: true, //スライドの高さを現在のスライドに合わせる
		// appendArrows: $('.p-homeDetail__pager'),
		prevArrow: '<button class="slide-arrow prev-arrow"></button>',
		nextArrow: '<button class="slide-arrow next-arrow"></button>',
	});
}
