'use strict'

$('#js-hamburger').click(function(){
	$(this).toggleClass('is-active')
	$('#js-header').toggleClass('is-show')
	$('#js-navigation').toggleClass('is-show')
	$('html').toggleClass('is-fixed')
})
$('.l-navigation__link').click(function(){
	$('#js-hamburger').removeClass('is-active')
	$('#js-header').removeClass('is-show')
	$('#js-navigation').removeClass('is-show')
	$('html').removeClass('is-fixed')
})


//selectによる言語切替
$('.l-navigationLanguage__select').change(function() {
	let _language = $('option:selected').val();
	// let _hash = window.location.hash;
	let _currentPage = location.pathname;
	// if(_hash != ''){
	// 	_currentPage = _currentPage + _hash;
	// }
	if(_language == 'JP') {
		if(_currentPage.indexOf('/jp') === 0){
			return;
		} else {
			window.location.href = '/jp' + _currentPage;
		}
	} else {
		if(_currentPage.indexOf('/jp') === 0){
			_currentPage = _currentPage.replace('/jp','')
			window.location.href = _currentPage;
		} else {
			return;
		}
	}
})
